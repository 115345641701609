import Swiper, {Navigation, A11y, Autoplay, FreeMode} from 'swiper';

/**
 * Slider activation function
 * @param el {HTMLElement}
 * @param config {Object}
 * @returns {Swiper|boolean}
 */
function sliders(el, config = {}) {
	if (!el) {
		return false;
	}
	let slider = el.querySelector('.swiper');
	if (!slider) {
		return false;
	}
	let next       = el.querySelector('.swiper-button-next');
	let prev       = el.querySelector('.swiper-button-prev');
	let pagination = el.querySelector('.swiper-pagination');
	let settings   = {
		modules              : [Navigation, A11y],
		loop                 : false,
		slidesPerView        : 'auto',
		spaceBetween         : 0,
		preloadImages        : false,
		watchSlidesVisibility: true,
		watchOverflow        : true,
		threshold            : 10,
		observer             : true,
		navigation           : {
			nextEl: next,
			prevEl: prev
		},
		pagination           : {
			el            : pagination,
			type          : 'bullets',
			clickable     : true,
			dynamicBullets: false
		}
	}

	if (config) {
		Object.assign(settings, config);
	}

	return new Swiper(slider, settings);
}

function sliderInit() {
	// News slider
	const newsSlider = document.querySelectorAll('[data-slider="news"]');
	newsSlider.forEach(item => sliders(item));

	// Team slider
	const teamSlider = document.querySelectorAll('[data-slider="team"]');
	teamSlider.forEach(item => sliders(item, {
		threshold: 0
	}));

	// Strategy slider
	const strategySlider = document.querySelectorAll('[data-slider="strategy"]');
	strategySlider.forEach(item => sliders(item, {
		threshold: 0
	}));

	// Roadmap slider
	const roadmapSlider = document.querySelectorAll('[data-slider="roadmap"]');
	roadmapSlider.forEach(item => sliders(item, {
		threshold: 0
	}));
}

document.addEventListener('DOMContentLoaded', function (event) {
	sliderInit();
});
